import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import ProductHero from './modules/components/ProductHero';
import QuizApp from './QuizApp';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://tylerlcampbell.com/">
        TylerLCampbell . com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function App() {
  return (
    <Router>
      <Container
        maxWidth="false"
        disableGutters
        sx={{ backgroundColor: 'gray', width: '100%', minHeight: '100vh' }} // Ensure the background color extends to the bottom
      >
        <style>
          {`
          body, html {
            margin: 0;
            padding: 0;
            height: 100%;
            background-color: gray;
            font-family: Arial, sans-serif;
          }

          .app {
            min-height: 100%;
            background-color: gray;
            padding: 20px;
            box-sizing: border-box;
          }

          h1 {
            margin-top: 0;
            color: white;
          }

          h2 {
            margin-top: 0;
            color: white;
          }

          .nav-bar {
            display: flex;
            justify-content: center;
            padding: 10px;
            background-color: #333;
          }

          .nav-link {
            color: white;
            margin: 0 10px;
            text-decoration: none;
          }

          .nav-link.active {
            font-weight: bold;
          }

          button {
            display: block;
            margin: 10px 0;
            padding: 10px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }

          button:hover {
            background-color: #ddd;
          }

          input[type="file"] {
            margin-bottom: 20px;
          }

          ul {
            padding-left: 20px;
          }

          strong {
            font-weight: bold;
          }

          div {
            margin-bottom: 20px;
          }

          p {
            color: white;
          }
          `}
        </style>
        <nav className="nav-bar">
          <NavLink to="/" className="nav-link" activeClassName="active" exact>Home</NavLink>
          <NavLink to="/quiz" className="nav-link" activeClassName="active">Quiz</NavLink>
        </nav>
        <Routes>
          <Route path="/" element={<ProductHero />} />
          <Route path="/quiz" element={<QuizApp />} />
        </Routes>
        <Copyright />
      </Container>
    </Router>
  );
}
