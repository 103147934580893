import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import * as XLSX from 'xlsx';

const QuizApp = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showHint, setShowHint] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [showInstructions, setShowInstructions] = useState(false); // State to toggle instructions visibility

  const resetQuizState = () => {
    setCurrentQuestionIndex(0);
    setSelectedAnswer('');
    setFeedback('');
    setShowHint(false);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedQuestions = XLSX.utils.sheet_to_json(sheet);
      console.log('Parsed Questions:', parsedQuestions);
      setQuestions(parsedQuestions.sort(() => Math.random() - 0.5));
      resetQuizState();
    };
    reader.readAsArrayBuffer(file);
  };

  const handleAnswer = (answer) => {
    console.log('Selected Answer:', answer);
    const currentQuestion = questions[currentQuestionIndex] || {};
    console.log('Current Question:', currentQuestion);
    const correctAnswer = currentQuestion['Correct Answer'];
    console.log('Correct Answer:', correctAnswer);

    if (typeof correctAnswer === 'string') {
      const trimmedCorrectAnswer = correctAnswer.trim();
      const feedbackMessage = answer.trim() === trimmedCorrectAnswer ? 'Correct!' : (trimmedCorrectAnswer === '?' || trimmedCorrectAnswer === '') ? 'Unknown' : 'Incorrect';
      setFeedback(feedbackMessage);
      setSelectedAnswer(answer);
    } else {
      setFeedback('Invalid answer format.');
    }
  };

  const handleNextQuestion = () => {
    console.log('Advancing to Next Question');
    setCurrentQuestionIndex(prevIndex => prevIndex + 1);
    setSelectedAnswer('');
    setFeedback('');
    setShowHint(false);
  };

  const toggleHint = () => {
    setShowHint(prevShowHint => !prevShowHint);
  };

  const toggleInstructions = () => {
    setShowInstructions(prevShow => !prevShow); // Toggle the instructions visibility
  };

  const currentQuestion = questions[currentQuestionIndex] || {};
  console.log('Rendering Current Question:', currentQuestion);

  return (
    <div style={{ padding: '20px' }}>
      <h1>Quiz App</h1>
      <p>Upload your spreadsheet to start the quiz.</p>
      <input type="file" onChange={handleFileUpload} style={{ marginBottom: '20px' }} />
      <div style={{ marginBottom: '20px' }}>
        <button onClick={toggleInstructions} style={{ backgroundColor: '#555', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
          {showInstructions ? 'Hide Spreadsheet Structure' : 'Show Spreadsheet Structure'}
        </button>
        {showInstructions && (
          <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', color: 'black' }}>
            <h3>Spreadsheet Structure</h3>
            <p style={{ color: '#333' }}>Ensure your spreadsheet has the following columns (order doesn't matter):</p>
            <ul>
              <li><strong>Question</strong>: The quiz question.</li>
              <li><strong>Answers</strong>: Possible answers separated by new lines.</li>
              <li><strong>Correct Answer</strong>: The correct answer.</li>
              <li><strong>More Info</strong>: Additional information or hints.</li>
              <li><strong>Section</strong>: The section or category of the question.</li>
            </ul>
          </div>
        )}
      </div>
      {questions.length === 0 ? (
        <p>No questions loaded. Please upload a spreadsheet.</p>
      ) : (
        <>
          {currentQuestion.Question ? (
            <div>
              <h2>{currentQuestion.Question}</h2>
              <p style={{ fontSize: '0.9em', color: '#333' }}>Section: <strong>{currentQuestion.Section}</strong></p>
              <div style={{ marginBottom: '20px' }}>
                {currentQuestion.Answers?.split('\n').map(answer => (
                  <button
                    key={answer}
                    onClick={() => handleAnswer(answer)}
                    style={{ display: 'block', margin: '10px 0' }}
                  >
                    {answer}
                  </button>
                ))}
              </div>
              {selectedAnswer && <p>{selectedAnswer}: {feedback}</p>}
              <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
                <button 
                  onClick={toggleHint} 
                  style={{ 
                    backgroundColor: '#f0ad4e', 
                    color: 'white', 
                    padding: '10px 20px', 
                    border: 'none', 
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}>
                  {showHint ? 'Hide Hint' : 'Show Hint'}
                </button>
                {showHint && <p>{currentQuestion['More Info'] ? currentQuestion['More Info'] : 'N/A'}</p>}
                <button 
                  onClick={handleNextQuestion} 
                  style={{ 
                    backgroundColor: '#5bc0de', 
                    color: 'white', 
                    padding: '10px 20px', 
                    border: 'none', 
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}>
                  Next Question
                </button>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </>
      )}
    </div>
  );
};

export default QuizApp;
